import React from 'react';
import PropTypes from 'prop-types';
import { FaPhoneAlt, FaClock } from 'react-icons/fa'; // Import phone and clock icons
import './CostInfo.css'; // Import custom CSS

const CostInfo = ({ lowerCost, upperCost, phone, schedule, specializations }) => {
  const getCostSymbol = (lower, upper) => {
    const avgCost = (lower + upper) / 2;
    if (avgCost <= 50) return '$';
    if (avgCost <= 100) return '$$';
    return '$$$';
  };

  return (
    <div className="cost-info">
      <h4 className="cost-info-title">Cost:</h4>
      <p className="cost-info-value">
        <span className="cost-symbol">
          {getCostSymbol(lowerCost, upperCost)}
        </span>
        {lowerCost === upperCost
          ? `$${lowerCost}`
          : `$${lowerCost} - $${upperCost}`}
      </p>

      <h4 className="cost-info-title">Contact Info:</h4>
      <p className="cost-info-item">
        <FaPhoneAlt className="cost-info-icon" /> {phone || 'Not Available'}
      </p>

      <h4 className="cost-info-title">Meeting Time:</h4>
      <p className="cost-info-item">
        <FaClock className="cost-info-icon" /> {schedule || 'Not Scheduled'}
      </p>

      <h4 className="cost-info-title">Focus Group:</h4>
      <p className="cost-info-item">
        {specializations.length > 0
          ? specializations.join(', ')
          : 'No Specializations Listed'}
      </p>
    </div>
  );
};

CostInfo.propTypes = {
  lowerCost: PropTypes.number.isRequired,
  upperCost: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired,
  schedule: PropTypes.string.isRequired,
  specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CostInfo;
