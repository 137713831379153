import { useState, useEffect } from 'react';

export const useFetchInstance = (model, id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/api/${model}/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch the instance data.');
        }
        const instance = await response.json();
        setData(instance);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [model, id]);

  return { data, loading, error };
};
