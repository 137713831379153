import React from 'react';
import PropTypes from 'prop-types';
import { FaPhoneAlt, FaStar, FaClock, FaMedkit } from 'react-icons/fa';
import './PharmacyInfo.css';

const PharmacyInfo = ({ phone, rating, acceptsMedicaid, twenty_four_hour_service }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const totalStars = 5;

    return (
      <div className="rating-stars">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} className="rating-icon" />
        ))}
        {hasHalfStar && <FaStar className="rating-icon half-star" />}
        {[...Array(totalStars - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
          <FaStar key={index} className="rating-icon empty-star" />
        ))}
      </div>
    );
  };

  return (
    <div className="pharmacy-info-card">
      <h4 className="pharmacy-info-header">Rating</h4>
      <div className="pharmacy-info-item">{rating !== null ? renderStars(rating) : 'Not Rated'}</div>

      <h4 className="pharmacy-info-header">Contact Info</h4>
      <p className="pharmacy-info-item">
        <FaPhoneAlt className="pharmacy-info-icon" /> {phone || 'Not Available'}
      </p>

      <h4 className="pharmacy-info-header">Accepts Medicaid</h4>
      <p
        className={`pharmacy-info-item ${
          acceptsMedicaid ? 'accepts-medicaid' : 'no-medicaid'
        }`}
      >
        <FaMedkit className="pharmacy-info-icon" />
        {acceptsMedicaid ? 'Yes' : 'No'}
      </p>

      <h4 className="pharmacy-info-header">24 Hour Service</h4>
      <p
        className={`pharmacy-info-item ${
          twenty_four_hour_service ? 'accepts-medicaid' : 'no-medicaid'
        }`}
      >
        <FaMedkit className="pharmacy-info-icon" />
        {twenty_four_hour_service ? 'Yes' : 'No'}
      </p>
    </div>
  );
};

PharmacyInfo.propTypes = {
  phone: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  acceptsMedicaid: PropTypes.bool.isRequired,
  twenty_four_hour_service: PropTypes.bool.isRequired,
};

export default PharmacyInfo;
