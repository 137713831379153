import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PsychiatristsBarGraph = () => {
  const ref = useRef();

  useEffect(() => {
    // Fetch specializations data
    fetch('/api/specializations')
      .then((response) => response.json())
      .then((data) => {
        drawChart(data);
      })
      .catch((error) => {
        console.error('Error fetching specializations:', error);
      });
  }, []);

  const drawChart = (specializations) => {
    // Process data: Get the number of psychiatrists per specialization
    const data = specializations
      .map((spec) => ({
        spec: spec.name,
        count: spec.psychiatrists.length,
      }))
      .filter((d) => d.count >= 1); // Only include specializations with at least 1 psychiatrist
  
    // Sort data by count in descending order (optional)
    data.sort((a, b) => b.count - a.count);
  
    // Set dimensions
    const margin = { top: 20, right: 30, bottom: 100, left: 60 };
    const width = 800 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;
  
    // Remove existing SVG (if any)
    d3.select(ref.current).select('svg').remove();
  
    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  
    // X axis
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.spec))
      .padding(0.2);
  
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');
  
    // Y axis
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .range([height, 0]);
  
    svg.append('g').call(d3.axisLeft(y));
  
    // Bars
    svg
      .selectAll('mybar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.spec))
      .attr('y', (d) => y(d.count))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.count))
      .attr('fill', '#69b3a2');
  };

  return (
    <div>
      <h2 className="text-center">Number of Psychiatrists with Specialization</h2>
      <div ref={ref}></div>
    </div>
  );
};

export default PsychiatristsBarGraph;
