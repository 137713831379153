import React from 'react';
import PropTypes from 'prop-types';
import NavigationBar from './NavigationBar';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { usePharmacyStatus } from '../hooks/usePharmacyStatus'; // Import the custom hook
import PharmacyInfo from './PharmacyInfo';
import './PharmacyTemplate.css'; // Custom CSS

const PharmacyTemplate = ({ pharmacyData }) => {
  if (!pharmacyData) {
    return (
      <>
        <NavigationBar activePath="/pharmacies" />
        <Container className="mt-4 text-center">
          <h2 className="error-text">Error: Pharmacy data not available.</h2>
        </Container>
      </>
    );
  }

  const {
    name,
    address: { street, city, state, zip, place_id },
    phone,
    operating_hours_mon_fri: weekdays,
    operating_hours_sat: saturday,
    operating_hours_sun: sunday,
    external_link: externalLink,
    twenty_four_hour_service,
    accepts_medicaid,
    rating,
    photo_url,
    related_psychiatrists,
    related_support_groups,
  } = pharmacyData;

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;
  const googleMapsLink = `https://www.google.com/maps/place/?q=place_id:${place_id}`;

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const totalStars = 5;

    return (
      <div className="rating-stars">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {hasHalfStar && <FaStarHalfAlt />}
        {[...Array(totalStars - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
          <FaRegStar key={index} />
        ))}
      </div>
    );
  };

  return (
    <>
      <NavigationBar activePath="/pharmacies" />
      <Container className="mt-4 pharmacy-template-container">
        <Row>
          <Col md={6}>
            <Card className="pharmacy-card">
              <Card.Img variant="top" src={photo_url} alt={name} className="pharmacy-image" />
              <Card.Body>
                <Card.Title className="pharmacy-title">{name}</Card.Title>
                <div className="location-section">
                  <h4>Location:</h4>
                  <p>
                    {street}, {city}, {state}, {zip}
                  </p>
                  <div className="google-maps-container">
                    <iframe
                      src={googleMapsEmbedUrl}
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex="0"
                      title="Google Maps Embed"
                      className="google-maps-iframe"
                    ></iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="pharmacy-details-card">
              <Card.Body>
                <PharmacyInfo
                  phone={phone}
                  rating={rating}
                  acceptsMedicaid={accepts_medicaid}
                  twenty_four_hour_service={twenty_four_hour_service}
                />
                <div className="button-group">
                  <Button href={googleMapsLink} target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Pharmacy Website
                  </Button>
                  {related_psychiatrists?.length > 0 && (
                    <Button href={`/psychiatrists/${related_psychiatrists[0]}`} variant="secondary">
                      Related Psychiatrist
                    </Button>
                  )}
                  {related_support_groups?.length > 0 && (
                    <Button href={`/support-groups/${related_support_groups[0]}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PharmacyTemplate.propTypes = {
  pharmacyData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    phone: PropTypes.string.isRequired,
    twenty_four_hour_service: PropTypes.bool.isRequired,
    external_link: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    accepts_medicaid: PropTypes.bool.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_psychiatrists: PropTypes.array,
    related_support_groups: PropTypes.array,
  }).isRequired,
};

export default PharmacyTemplate;
