import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetchInstance } from '../hooks/useFetchInstance'; // Import the custom hook
import PharmacyTemplate from './PharmacyTemplate'; // Import different templates
import PsychiatristTemplate from './PsychiatristTemplate';
import SupportGroupTemplate from './SupportGroupTemplate';

const GeneralInstance = () => {
  const { model, id } = useParams(); // Get model (pharmacies, psychiatrists, support-groups) and id
  const { data, loading, error } = useFetchInstance(model, id); // Use the custom hook

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data) return <div>Instance not found</div>;

  // Render the appropriate template based on the model
  switch (model) {
    case 'pharmacies':
      return <PharmacyTemplate pharmacyData={data} />;
    case 'psychiatrists':
      return <PsychiatristTemplate psychiatristData={data} />;
    case 'support-groups':
      return <SupportGroupTemplate supportGroupData={data} />;
    default:
      return <div>Unknown model: {model}</div>;
  }
};

export default GeneralInstance;
