import pharmacyImg from '../images/Pharmacy.jpg';
import psychiatristImg from '../images/Psychiatrist.jpg';
import supportGroupImg from '../images/SupportGroup.jpg';

const modelImages = {
    pharmacies: {
      title: 'Pharmacies',
      description:
        'Discover pharmacies near you offering ADHD medication, 24-hour service, and more.',
      image: pharmacyImg,
    },
    psychiatrists: {
      title: 'Licensed Psychiatrists',
      description:
        'Connect with licensed psychiatrists specializing in ADHD treatment and therapy.',
      image: psychiatristImg,
    },
    'support-groups': {
      title: 'Support Groups',
      description:
        'Join support groups offering community engagement, shared experiences, and helpful resources.',
      image: supportGroupImg,
    },
  };
  
  export default modelImages;
  