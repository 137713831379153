import React from 'react';
import PropTypes from 'prop-types';
import InstanceCard from './InstanceCard';
import './CategoryResults.css'; // Import custom CSS

const CategoryResults = ({ category, items, searchTerm }) => (
  <div className="category-results mb-5">
    <h3 className="category-title">{category}</h3>
    <div className="horizontal-scroll-container">
      {items.length > 0 ? (
        items.map((instance, index) => (
          <div key={instance.id} className="horizontal-scroll-item">
            <InstanceCard
              instance={instance}
              modelType={category.toLowerCase().replace(' ', '-')}
              index={index}
              searchTerm={searchTerm}
            />
          </div>
        ))
      ) : (
        <p className="no-results-message">No results found in {category}.</p>
      )}
    </div>
  </div>
);

CategoryResults.propTypes = {
  category: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default CategoryResults;
