import { useState, useEffect } from 'react';

export const useSearchResults = (searchTerm) => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!searchTerm) {
      setResults({});
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    const fetchResults = async () => {
      setLoading(true);
      setError(null);

      try {
        const endpoints = ['/api/support-groups', '/api/psychiatrists', '/api/pharmacies'];
        const fetchPromises = endpoints.map((endpoint) =>
          fetch(`${endpoint}?search_term=${encodeURIComponent(searchTerm)}`, { signal }).then((res) => res.json())
        );

        const [supportGroups, psychiatrists, pharmacies] = await Promise.all(fetchPromises);

        setResults({
          'Support Groups': supportGroups.data || [],
          Psychiatrists: psychiatrists.data || [],
          Pharmacies: pharmacies.data || [],
        });
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError('Failed to fetch search results. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchResults();

    return () => controller.abort();
  }, [searchTerm]);

  return { results, loading, error };
};
