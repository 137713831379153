import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Card } from 'react-bootstrap';

const ProviderServicesDistribution = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];
  const BASE_URL = 'https://immigraid-backend.immigraid.me/api';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          fetch(`${BASE_URL}/educational-data`),
          fetch(`${BASE_URL}/healthcare-data`),
          fetch(`${BASE_URL}/legal-services-data`)
        ]);

        const [educationalData, healthcareData, legalData] = await Promise.all(
          responses.map(response => response.json())
        );

        const formattedData = [
          {
            name: 'Educational',
            value: Array.isArray(educationalData) ? educationalData.length : 0,
          },
          {
            name: 'Healthcare',
            value: Array.isArray(healthcareData) ? healthcareData.length : 0,
          },
          {
            name: 'Legal',
            value: Array.isArray(legalData) ? legalData.length : 0,
          }
        ];

        setData(formattedData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Card className="mx-auto" style={{ maxWidth: '800px' }}>
        <Card.Body>
          <div className="text-center">Loading...</div>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mx-auto" style={{ maxWidth: '800px' }}>
        <Card.Body>
          <div className="text-center text-danger">{error}</div>
        </Card.Body>
      </Card>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border rounded shadow">
          <p className="fw-bold mb-0">{payload[0].name}</p>
          <p className="text-secondary mb-0">Count: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="mx-auto" style={{ maxWidth: '800px' }}>
      <Card.Header>
        <h4 className="text-center mb-0">Distribution of Services</h4>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-center">
          <PieChart width={600} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={true}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
          </PieChart>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProviderServicesDistribution;
