import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import './NavigationBar.css'; // Import custom CSS for styling

function NavigationBar({ activePath, instancesPerPage, currentPage }) {
    const navLinks = [
        { path: '/', label: 'Home' },
        { path: '/about', label: 'About' },
        { path: `/support-groups?page=${currentPage}&limit=${instancesPerPage}`, label: 'Support Groups' },
        { path: `/psychiatrists?page=${currentPage}&limit=${instancesPerPage}`, label: 'Licensed Psychiatrists' },
        { path: `/pharmacies?page=${currentPage}&limit=${instancesPerPage}`, label: 'Pharmacies' },
        { path: '/visualizations', label: 'Visualizations' },
        { path: '/provider-visualizations', label: 'Provider Visualizations' }
    ];

    const getNavLinkClass = (path) => (path.includes(activePath) || (activePath === '/home' && path === '/') ? 'active' : '');

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
            <Navbar.Brand href="/" className="navbar-brand">
                TX ADHD Hub
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {navLinks.map(({ path, label }) => (
                        <Nav.Link
                            key={path}
                            href={path}
                            className={`nav-link ${getNavLinkClass(path)}`}
                        >
                            {label}
                        </Nav.Link>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

NavigationBar.propTypes = {
    activePath: PropTypes.string.isRequired,
    instancesPerPage: PropTypes.number,
    currentPage: PropTypes.number,
};

NavigationBar.defaultProps = {
    instancesPerPage: 9,
    currentPage: 1,
};

export default NavigationBar;
