import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './PharmacyFilter.css'; // Custom CSS
import uniqueCities from '../filterData/pharmacies_cities.json';
import uniqueZipCodes from '../filterData/pharmacies_zip_codes.json';

const PharmacyFilter = ({
  city,
  setCity,
  zipCode,
  setZipCode,
  twentyFourHourService,
  setTwentyFourHourService,
  homeDelivery,
  setHomeDelivery,
  acceptsMedicaid,
  setAcceptsMedicaid,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}) => {
  return (
    <Form className="pharmacy-filter">
      <Row className="align-items-center justify-content-center filter-row">
        {/* City */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacyCity">
            <Form.Label>City</Form.Label>
            <Typeahead
              id="city-typeahead"
              onChange={(selected) => {
                setCity(selected[0] || '');
              }}
              options={uniqueCities}
              placeholder="Choose a city..."
              selected={city ? [city] : []}
              highlightOnlyResult
              allowNew={false}
              className="typeahead-input"
            />
          </Form.Group>
        </Col>

        {/* Zip Code */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacyZipCode">
            <Form.Label>Zip Code</Form.Label>
            <Typeahead
              id="zip-code-typeahead"
              onChange={(selected) => {
                setZipCode(selected[0] || '');
              }}
              options={uniqueZipCodes}
              placeholder="Choose a zip code..."
              selected={zipCode ? [zipCode] : []}
              highlightOnlyResult
              allowNew={false}
              className="typeahead-input"
            />
          </Form.Group>
        </Col>

        {/* 24-Hour Service */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacy24HourService">
            <Form.Label>24-Hour Service</Form.Label>
            <Form.Control
              as="select"
              value={twentyFourHourService}
              onChange={(e) => setTwentyFourHourService(e.target.value)}
              className="select-input"
            >
              <option value="">Any</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Home Delivery */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacyHomeDelivery">
            <Form.Label>Home Delivery</Form.Label>
            <Form.Control
              as="select"
              value={homeDelivery}
              onChange={(e) => setHomeDelivery(e.target.value)}
              className="select-input"
            >
              <option value="">Any</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Accepts Medicaid */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacyAcceptsMedicaid">
            <Form.Label>Accepts Medicaid</Form.Label>
            <Form.Control
              as="select"
              value={acceptsMedicaid}
              onChange={(e) => setAcceptsMedicaid(e.target.value)}
              className="select-input"
            >
              <option value="">Any</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Sort By */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacySortBy">
            <Form.Label>Sort By</Form.Label>
            <Form.Control
              as="select"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="select-input"
            >
              <option value="">Default</option>
              <option value="name">Name</option>
              <option value="city">City</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Sort Order */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="pharmacySortOrder">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              as="select"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              className="select-input"
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default PharmacyFilter;
