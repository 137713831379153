import React from 'react';
import PropTypes from 'prop-types';
import { FaPhoneAlt, FaDollarSign, FaShieldAlt } from 'react-icons/fa';
import './ContactInfo.css';

const ContactInfo = ({ phone, specializations, cost, acceptsInsurance }) => (
  <div className="contact-info-card">
    <h4 className="contact-info-header">Contact Info</h4>
    <p className="contact-info-item">
      <FaPhoneAlt className="contact-info-icon" /> {phone}
    </p>

    <h4 className="contact-info-header">Specializations</h4>
    <p className="contact-info-item">{specializations.join(', ')}</p>

    <h4 className="contact-info-header">Cost</h4>
    <p className="contact-info-item">
      <FaDollarSign className="contact-info-icon" /> ${cost}
    </p>

    <h4 className="contact-info-header">Accepts Insurance</h4>
    <p
      className={`contact-info-item ${
        acceptsInsurance ? 'accepts-insurance' : 'no-insurance'
      }`}
    >
      <FaShieldAlt className="contact-info-icon" />
      {acceptsInsurance ? 'Yes' : 'No'}
    </p>
  </div>
);

ContactInfo.propTypes = {
  phone: PropTypes.string.isRequired,
  specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
  cost: PropTypes.number.isRequired,
  acceptsInsurance: PropTypes.bool.isRequired,
};

export default ContactInfo;
