import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CostInfo from './CostInfo'; // Import the CostInfo component
import './SupportGroupTemplate.css'; // Custom CSS

const SupportGroupTemplate = ({ supportGroupData }) => {
  if (!supportGroupData) {
    return (
      <>
        <NavigationBar activePath="/support-groups" />
        <Container className="mt-4 text-center">
          <h2 className="error-text">Error: Support group data not available.</h2>
        </Container>
      </>
    );
  }

  const {
    name,
    description,
    schedule,
    address: { street, city, state, zip, place_id },
    specializations,
    lower_cost: lowerCost,
    upper_cost: upperCost,
    phone,
    photo_url,
    related_pharmacies: relatedPharmacies,
    related_psychiatrists: relatedPsychiatrists,
  } = supportGroupData;

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;

  return (
    <>
      <NavigationBar activePath="/support-groups" />
      <Container className="mt-4 support-group-template-container">
        <Row>
          {/* Left: Support Group Image, Name, and Description */}
          <Col md={6}>
            <Card className="support-group-card">
              <Card.Img variant="top" src={photo_url} alt={name} className="support-group-image" />
              <Card.Body>
                <Card.Title className="support-group-title">{name}</Card.Title>
                <Card.Text>
                  <h4>Description</h4>
                  {description}
                </Card.Text>
                {/* Embed Google Map */}
                <div className="location-section">
                  <h4>Location:</h4>
                  <p>
                    {street}, {city}, {state}, {zip}
                  </p>
                  <div className="google-maps-container">
                    <iframe
                      src={googleMapsEmbedUrl}
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex="0"
                      title="Google Maps Embed"
                      className="google-maps-iframe"
                    ></iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Support Group Details */}
          <Col md={6}>
            <Card className="support-group-details-card">
              <Card.Body>
                <CostInfo
                  lowerCost={lowerCost}
                  upperCost={upperCost}
                  phone={phone}
                  schedule={schedule}
                  specializations={specializations}
                />

                <div className="mt-4 related-connections">
                  <h4>Related Connections:</h4>
                  {relatedPharmacies && relatedPharmacies.length > 0 && (
                    <Button
                      href={`/pharmacies/${relatedPharmacies[0]}`}
                      variant="secondary"
                      className="me-2"
                    >
                      Related Pharmacy
                    </Button>
                  )}
                  {relatedPsychiatrists && relatedPsychiatrists.length > 0 && (
                    <Button href={`/psychiatrists/${relatedPsychiatrists[0]}`} variant="secondary">
                      Related Psychiatrist
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

SupportGroupTemplate.propTypes = {
  supportGroupData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    schedule: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
    lower_cost: PropTypes.number.isRequired,
    upper_cost: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_pharmacies: PropTypes.array,
    related_psychiatrists: PropTypes.array,
  }).isRequired,
};

export default SupportGroupTemplate;
