import React from 'react';
import NavigationBar from './NavigationBar';
// Import your new visualization components here
import ProviderServicesDistribution from './ProviderServicesDistribution';
import ProviderEducationByState from './ProviderEducationByState';
import ProviderHealthcareBubble from './ProviderHealthcareBubble';
import { Container } from 'react-bootstrap';
import ProviderCritiques from './ProviderCritiques';
import './ProviderVisualizations.css'; // Custom CSS

const ProviderVisualizations = () => {
  return (
    <div>
      <NavigationBar activePath="/provider-visualizations" />
      <Container className="mt-4 provider-visualizations-container">
        <h1 className="text-center main-title">Provider Visualizations</h1>

        {/* Provider Services Distribution */}
        <div className="visualization-section">
          <h2 className="section-title">Services Distribution</h2>
          <div className="chart-container">
            <ProviderServicesDistribution />
          </div>
        </div>

        {/* Provider Education by State */}
        <div className="visualization-section">
          <h2 className="section-title">Education by State</h2>
          <div className="chart-container">
            <ProviderEducationByState />
          </div>
        </div>

        {/* Provider Healthcare Bubble */}
        <div className="visualization-section">
          <h2 className="section-title">Healthcare Providers Overview</h2>
          <div className="chart-container">
            <ProviderHealthcareBubble />
          </div>
        </div>
        <ProviderCritiques />
      </Container>
    </div>
  );
};

export default ProviderVisualizations;
