import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './TeamMemberCard.css'; // Import custom CSS

const TeamMemberCard = ({ member, stats }) => (
  <Card className="team-member-card">
    <Card.Img
      variant="top"
      src={member.image}
      alt={member.name}
      className="team-member-image"
    />
    <Card.Body className="d-flex flex-column">
      <Card.Title className="team-member-name">{member.name}</Card.Title>
      <Card.Text className="team-member-about">
        {member.about}
        <br /><br />
        <span className="team-member-role">Role:</span> {member.role}
        <br />
        <span className="team-member-stats">Commits:</span> {stats?.commits || 'Loading...'}
        <br />
        <span className="team-member-stats">Open Issues:</span> {stats?.open_issues || 'Loading...'}
        <br />
        <span className="team-member-stats">Closed Issues:</span> {stats?.closed_issues || 'Loading...'}
        <br />
        <span className="team-member-stats">Unit Tests:</span> {stats?.tests || 'Loading...'}
      </Card.Text>
      <div className="mt-auto"></div>
    </Card.Body>
  </Card>
);

TeamMemberCard.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  stats: PropTypes.shape({
    commits: PropTypes.number,
    open_issues: PropTypes.number,
    closed_issues: PropTypes.number,
    tests: PropTypes.number,
  }),
};

export default TeamMemberCard;
