import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { formatKey, highlightText } from '../utils/helpers'; // Import helpers
import './InstanceCard.css'; // Import custom CSS

const InstanceCard = ({ instance, modelType, index, searchTerm }) => {
  let modelKey;

  // Updated keys to reflect the API response structure
  switch (modelType) {
    case 'pharmacies':
      modelKey = ["rating", "twenty_four_hour_service", "address", "phone", "accepts_medicaid"];
      break;
    case 'psychiatrists':
      modelKey = ["specializations", "address", "cost", "phone", "accepts_insurance"];
      break;
    case 'support-groups':
      modelKey = ["schedule", "address", "specializations", "lower_cost", "therapy_types"];
      break;
    default:
      modelKey = [];
      break;
  }

  return (
    <Link to={`/${modelType}/${instance.id}`} className="instance-card-link">
      <Card className="instance-card">
        <Card.Img
          variant="top"
          src={instance['photo_url'] || instance['imageUrl']}
          alt={instance["name"]}
          className="instance-card-img"
        />
        <Card.Body className="d-flex flex-column">
          <Card.Title className="instance-card-title">
            {highlightText(instance["name"], searchTerm)}
          </Card.Title>
          {modelKey.map((key, idx) => {
            if (key === "address") {
              const { street, city, state, zip } = instance.address || {};
              const address = street ? `${street}, ${city}, ${state}, ${zip}` : 'N/A';
              return (
                <Card.Text key={idx} className="instance-card-text">
                  <strong>Address:</strong> {highlightText(address, searchTerm)}
                </Card.Text>
              );
            }

            if (typeof instance[key] === "boolean") {
              return (
                <Card.Text key={idx} className="instance-card-text">
                  <strong>{formatKey(key)}:</strong> {instance[key] ? "Yes" : "No"}
                </Card.Text>
              );
            }

            if (Array.isArray(instance[key])) {
              const text = instance[key].length > 0 ? instance[key].join(', ') : 'N/A';
              return (
                <Card.Text key={idx} className="instance-card-text">
                  <strong>{formatKey(key)}:</strong> {highlightText(text, searchTerm)}
                </Card.Text>
              );
            }

            const text = instance[key] || 'N/A';
            return (
              <Card.Text key={idx} className="instance-card-text">
                <strong>{formatKey(key)}:</strong> {highlightText(text, searchTerm)}
              </Card.Text>
            );
          })}
          <div className="mt-auto"></div>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default InstanceCard;
