import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import InstanceCard from './InstanceCard.js';
import { Container, Row, Col, Button, Image, Form } from 'react-bootstrap';
import modelImages from '../config/modelImages';
import PharmacyFilter from './PharmacyFilter';
import PsychiatristFilter from './PsychiatristFilter';
import SupportGroupFilter from './SupportGroupFilter';
import './ModelList.css'; // Import custom CSS

const useQuery = () => new URLSearchParams(useLocation().search);

const ModelList = () => {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { model } = useParams();
  const query = useQuery();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(query.get('search_term') || '');
  const instancesPerPage = parseInt(query.get('limit')) || 9;
  const currentPage = parseInt(query.get('page')) || 1;

  // Filter state initialization
  const initialCity = query.get('city') || '';
  const initialZipCode = query.get('zip_code') || '';
  const initialMinCost = query.get('min_cost') || '';
  const initialMaxCost = query.get('max_cost') || '';
  const initialSpecializations = query.get('specializations') ? query.get('specializations').split(',') : [];
  const initialInPerson = query.get('in_person') || '';

  const initialSgCity = query.get('sg_city') || '';
  const initialSgZipCode = query.get('sg_zip_code') || '';
  const initialAgeGroup = query.get('age_group') || '';
  const initialTherapyTypes = query.get('therapy_types') ? query.get('therapy_types').split(',') : [];
  const initialDays = query.get('days') ? query.get('days').split(',') : [];

  const initialPharmacyCity = query.get('pharmacy_city') || '';
  const initialPharmacyZipCode = query.get('pharmacy_zip_code') || '';
  const initialTwentyFourHourService = query.get('twenty_four_hour_service') || '';
  const initialHomeDelivery = query.get('home_delivery') || '';
  const initialAcceptsMedicaid = query.get('accepts_medicaid') || '';

  const initialSortBy = query.get('sort_by') || '';
  const initialSortOrder = query.get('order') || 'asc';

  // State variables for filters
  const [city, setCity] = useState(initialCity);
  const [zipCode, setZipCode] = useState(initialZipCode);
  const [minCost, setMinCost] = useState(initialMinCost);
  const [maxCost, setMaxCost] = useState(initialMaxCost);
  const [selectedSpecializations, setSelectedSpecializations] = useState(initialSpecializations);
  const [inPerson, setInPerson] = useState(initialInPerson);

  const [sgCity, setSgCity] = useState(initialSgCity);
  const [sgZipCode, setSgZipCode] = useState(initialSgZipCode);
  const [ageGroup, setAgeGroup] = useState(initialAgeGroup);
  const [selectedTherapyTypes, setSelectedTherapyTypes] = useState(initialTherapyTypes);
  const [selectedDays, setSelectedDays] = useState(initialDays);

  const [pharmacyCity, setPharmacyCity] = useState(initialPharmacyCity);
  const [pharmacyZipCode, setPharmacyZipCode] = useState(initialPharmacyZipCode);
  const [twentyFourHourService, setTwentyFourHourService] = useState(initialTwentyFourHourService);
  const [homeDelivery, setHomeDelivery] = useState(initialHomeDelivery);
  const [acceptsMedicaid, setAcceptsMedicaid] = useState(initialAcceptsMedicaid);

  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  useEffect(() => {
    // Create an AbortController to cancel previous requests
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      try {
        setLoading(true); // Set loading state to true before fetching

        // Construct the API URL with the updated search term
        let apiUrl = `/api/${model}?page=${currentPage}&limit=${instancesPerPage}`;
        if (searchTerm) {
          apiUrl += `&search_term=${encodeURIComponent(searchTerm)}`;
        }
        if (sortBy) apiUrl += `&sort_by=${sortBy}`;
        if (sortOrder) apiUrl += `&order=${sortOrder}`;
        // Add filter parameters to apiUrl
        if (model === 'psychiatrists') {
          if (city) apiUrl += `&city=${encodeURIComponent(city)}`;
          if (zipCode) apiUrl += `&zip_code=${encodeURIComponent(zipCode)}`;
          if (minCost) apiUrl += `&min_cost=${minCost}`;
          if (maxCost) apiUrl += `&max_cost=${maxCost}`;
          if (selectedSpecializations.length > 0) {
            apiUrl += `&specializations=${encodeURIComponent(selectedSpecializations.join(','))}`;
          }
          if (inPerson !== '') apiUrl += `&in_person=${inPerson}`;
        } else if (model === 'support-groups') {
          if (sgCity) apiUrl += `&city=${encodeURIComponent(sgCity)}`;
          if (sgZipCode) apiUrl += `&zip_code=${encodeURIComponent(sgZipCode)}`;
          if (ageGroup) apiUrl += `&age_group=${encodeURIComponent(ageGroup)}`;
          if (selectedTherapyTypes.length > 0) {
            apiUrl += `&therapy_types=${encodeURIComponent(selectedTherapyTypes.join(','))}`;
          }
          if (selectedDays.length > 0) {
            apiUrl += `&days=${encodeURIComponent(selectedDays.join(','))}`;
          }
        } else if (model === 'pharmacies') {
          if (pharmacyCity) apiUrl += `&city=${encodeURIComponent(pharmacyCity)}`;
          if (pharmacyZipCode) apiUrl += `&zip_code=${encodeURIComponent(pharmacyZipCode)}`;
          if (twentyFourHourService !== '') apiUrl += `&twenty_four_hour_service=${twentyFourHourService}`;
          if (homeDelivery !== '') apiUrl += `&home_delivery=${homeDelivery}`;
          if (acceptsMedicaid !== '') apiUrl += `&accepts_medicaid=${acceptsMedicaid}`;
        }

        const response = await fetch(apiUrl, { signal }); // Pass the signal to the fetch request
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const { data, total_count } = await response.json();
        setInstances(data);
        setTotalCount(total_count);
        setTotalPages(Math.ceil(total_count / instancesPerPage));
      } catch (err) {
        if (err.name !== 'AbortError') {
          // Only set the error if it's not an abort error
          console.error('Error fetching data:', err);
          setError(err.message);
        }
      } finally {
        setLoading(false); // Set loading state to false after fetching
      }
    };

    fetchData();

    // Cleanup function to abort the fetch request if a new one is initiated
    return () => {
      controller.abort();
    };
  }, [
    model,
    currentPage,
    instancesPerPage,
    searchTerm,
    city,
    zipCode,
    minCost,
    maxCost,
    selectedSpecializations,
    inPerson,
    sgCity,
    sgZipCode,
    ageGroup,
    selectedTherapyTypes,
    selectedDays,
    pharmacyCity,
    pharmacyZipCode,
    twentyFourHourService,
    homeDelivery,
    acceptsMedicaid,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    // Update URL and trigger fetch when searchTerm changes
    const params = buildURLParams(1);
    navigate(`/${model}?${params.toString()}`, { replace: true });
  }, [searchTerm]);

  const buildURLParams = (page) => {
    const params = new URLSearchParams();
    params.set('page', page.toString());
    params.set('limit', instancesPerPage.toString());
    if (searchTerm) {
      params.set('search_term', searchTerm);
    }
    if (city) params.set('city', city);
    if (zipCode) params.set('zip_code', zipCode);
    if (minCost) params.set('min_cost', minCost);
    if (maxCost) params.set('max_cost', maxCost);
    if (selectedSpecializations.length > 0) {
      params.set('specializations', selectedSpecializations.join(','));
    }
    if (inPerson !== '') params.set('in_person', inPerson);

    if (sgCity) params.set('sg_city', sgCity);
    if (sgZipCode) params.set('sg_zip_code', sgZipCode);
    if (ageGroup) params.set('age_group', ageGroup);
    if (selectedTherapyTypes.length > 0) {
      params.set('therapy_types', selectedTherapyTypes.join(','));
    }
    if (selectedDays.length > 0) {
      params.set('days', selectedDays.join(','));
    }

    if (pharmacyCity) params.set('pharmacy_city', pharmacyCity);
    if (pharmacyZipCode) params.set('pharmacy_zip_code', pharmacyZipCode);
    if (twentyFourHourService !== '') {
      params.set('twenty_four_hour_service', twentyFourHourService);
    }
    if (homeDelivery !== '') {
      params.set('home_delivery', homeDelivery);
    }
    if (acceptsMedicaid !== '') {
      params.set('accepts_medicaid', acceptsMedicaid);
    }
    if (sortBy) {
      params.set('sort_by', sortBy);
    }
    if (sortOrder) {
      params.set('order', sortOrder);
    }

    return params;
  };

  const handleFilterChange = () => {
    const params = buildURLParams(currentPage);
    navigate(`/${model}?${params.toString()}`, { replace: true });
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    city,
    zipCode,
    minCost,
    maxCost,
    selectedSpecializations,
    inPerson,
    sgCity,
    sgZipCode,
    ageGroup,
    selectedTherapyTypes,
    selectedDays,
    pharmacyCity,
    pharmacyZipCode,
    twentyFourHourService,
    homeDelivery,
    acceptsMedicaid,
    sortBy,
    sortOrder
  ]);

  if (error) return <div>Error: {error}</div>;

  const modelData = modelImages[model] || {};
  const { title, modelDescription, modelImage } = modelData;

  return (
    <div>
      <NavigationBar activePath={`/${model}`} />
      <Container className="mt-4 model-list-container">
        <h1 className="text-center model-title">{title}</h1>
        <div className="text-center">
          {modelImage && (
            <Image
              src={modelImage}
              alt={model}
              className="model-image"
              fluid
            />
          )}
        </div>
        <p className="text-center model-description">{modelDescription}</p>

        <Form className="mb-4 search-bar-form" onSubmit={(e) => e.preventDefault()}>
          <Form.Group controlId="searchTerm">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Real-time search
            />
          </Form.Group>
        </Form>

        <div className="filters mb-4">
          {model === 'pharmacies' && (
            <PharmacyFilter
              city={pharmacyCity}
              setCity={(value) => {
                setPharmacyCity(value);
                handleFilterChange();
              }}
              zipCode={pharmacyZipCode}
              setZipCode={(value) => {
                setPharmacyZipCode(value);
                handleFilterChange();
              }}
              twentyFourHourService={twentyFourHourService}
              setTwentyFourHourService={(value) => {
                setTwentyFourHourService(value);
                handleFilterChange();
              }}
              homeDelivery={homeDelivery}
              setHomeDelivery={(value) => {
                setHomeDelivery(value);
                handleFilterChange();
              }}
              acceptsMedicaid={acceptsMedicaid}
              setAcceptsMedicaid={(value) => {
                setAcceptsMedicaid(value);
                handleFilterChange();
              }}
              sortBy={sortBy}
              setSortBy={(value) => {
                setSortBy(value);
                handleFilterChange();
              }}
              sortOrder={sortOrder}
              setSortOrder={(value) => {
                setSortOrder(value);
                handleFilterChange();
              }}
            />
          )}
          {model === 'psychiatrists' && (
            <PsychiatristFilter
              city={city}
              setCity={(value) => {
                setCity(value);
                handleFilterChange();
              }}
              zipCode={zipCode}
              setZipCode={(value) => {
                setZipCode(value);
                handleFilterChange();
              }}
              minCost={minCost}
              setMinCost={(value) => {
                setMinCost(value);
                handleFilterChange();
              }}
              maxCost={maxCost}
              setMaxCost={(value) => {
                setMaxCost(value);
                handleFilterChange();
              }}
              selectedSpecializations={selectedSpecializations}
              setSelectedSpecializations={(value) => {
                setSelectedSpecializations(value);
                handleFilterChange();
              }}
              inPerson={inPerson}
              setInPerson={(value) => {
                setInPerson(value);
                handleFilterChange();
              }}
              sortBy={sortBy}
              setSortBy={(value) => {
                setSortBy(value);
                handleFilterChange();
              }}
              sortOrder={sortOrder}
              setSortOrder={(value) => {
                setSortOrder(value);
                handleFilterChange();
              }}
            />
          )}
          {model === 'support-groups' && (
            <SupportGroupFilter
              city={sgCity}
              setCity={(value) => {
                setSgCity(value);
                handleFilterChange();
              }}
              zipCode={sgZipCode}
              setZipCode={(value) => {
                setSgZipCode(value);
                handleFilterChange();
              }}
              ageGroup={ageGroup}
              setAgeGroup={(value) => {
                setAgeGroup(value);
                handleFilterChange();
              }}
              selectedTherapyTypes={selectedTherapyTypes}
              setSelectedTherapyTypes={(value) => {
                setSelectedTherapyTypes(value);
                handleFilterChange();
              }}
              selectedDays={selectedDays}
              setSelectedDays={(value) => {
                setSelectedDays(value);
                handleFilterChange();
              }}
              sortBy={sortBy}
              setSortBy={(value) => {
                setSortBy(value);
                handleFilterChange();
              }}
              sortOrder={sortOrder}
              setSortOrder={(value) => {
                setSortOrder(value);
                handleFilterChange();
              }}
            />
          )}
        </div>

        <Row className="justify-content-center align-items-stretch">
          {loading ? (
            <Col className="text-center loading-state">Loading...</Col>
          ) : instances.length > 0 ? (
            instances.map((instance, index) => (
              <Col
                key={index}
                md={4}
                className="mb-4 d-flex justify-content-center align-items-stretch"
              >
                <div className="card-wrapper">
                  <InstanceCard
                    instance={instance}
                    modelType={model}
                    index={index}
                    searchTerm={searchTerm}
                  />
                </div>
              </Col>
            ))
          ) : (
            <Col className="text-center no-data">No data found for this model.</Col>
          )}
        </Row>

        <p>Total: {totalCount}</p>
        <div className="pagination-controls">
          <Button
            onClick={() => {
              if (currentPage > 1) {
                const params = buildURLParams(currentPage - 1);
                navigate(`/${model}?${params.toString()}`);
              }
            }}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={() => {
              if (currentPage < totalPages) {
                const params = buildURLParams(currentPage + 1);
                navigate(`/${model}?${params.toString()}`);
              }
            }}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ModelList;
