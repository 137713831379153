import { useState, useEffect } from 'react';

export const usePharmacyStatus = (placeId, hours) => {
  const [status, setStatus] = useState('Closed');
  const [rating, setRating] = useState(null);

  const calculateStatus = (hours) => {
    // Logic to calculate open/closed status
    if (!hours) return 'Closed';
    const now = new Date();
    const [start, end] = hours.split('-').map(time => time.trim());
    const parseTime = (time) => {
      const [hour, minute] = time.slice(0, -2).split(':').map(Number);
      const isPM = time.endsWith('PM');
      return hour + (isPM && hour < 12 ? 12 : 0) + minute / 60;
    };
    const currentTime = now.getHours() + now.getMinutes() / 60;
    const openingTime = parseTime(start);
    const closingTime = parseTime(end);

    if (currentTime >= openingTime && currentTime <= closingTime) {
      return currentTime >= closingTime - 1 ? 'Closing Soon' : 'Open';
    }
    return 'Closed';
  };

  useEffect(() => {
    const currentHours =
      new Date().getDay() === 0
        ? hours.sunday
        : new Date().getDay() === 6
        ? hours.saturday
        : hours.weekdays;

    setStatus(calculateStatus(currentHours));

    const fetchRating = async () => {
      if (!window.google) return;
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(
        { placeId, fields: ['rating'] },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setRating(place.rating);
          }
        }
      );
    };

    fetchRating();
  }, [placeId, hours]);

  return { status, rating };
};
