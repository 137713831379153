// ../utils/helpers.js

/**
 * Formats a key string into a user-friendly label.
 * @param {string} key - The key to format.
 * @returns {string} - The formatted key.
 */
export const formatKey = (key) => {
    const keyMappings = {
      'twenty_four_hour_service': '24-Hour Service',
      'accepts_medicaid': 'Accepts Medicaid',
      'accepts_insurance': 'Accepts Insurance',
      'lower_cost': 'Lower Cost',
      'upper_cost': 'Upper Cost',
      'therapy_types': 'Therapy Types',
      'in_person': 'In-Person',
      'online': 'Online',
      'waitlist': 'Waitlist',
      'unavailable': 'Unavailable',
      // Add more custom mappings as needed
    };
  
    if (keyMappings[key]) {
      return keyMappings[key];
    }
  
    // Replace underscores with spaces and capitalize each word
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  /**
   * Highlights occurrences of the search term in the text.
   * @param {string} text - The text to search within.
   * @param {string} searchTerm - The term to highlight.
   * @returns {React.ReactNode} - The text with highlighted search terms.
   */
  export const highlightText = (text, searchTerm) => {
    if (!searchTerm || typeof text !== 'string') return text;
  
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);
  
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  