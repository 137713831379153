import React from 'react';
import NavigationBar from './NavigationBar';
import ModelsPieChart from './ModelsPieChart';
import PharmaciesHeatMap from './PharmaciesHeatMap';
import PsychiatristsBarGraph from './PsychiatristsBarGraph';
import { Container } from 'react-bootstrap';
import SelfCritiques from './SelfCritiques';
import './Visualizations.css'; // Custom CSS

const Visualizations = () => {
  return (
    <div>
      <NavigationBar activePath="/visualizations" />
      <Container className="mt-4 visualizations-container">
        <h1 className="text-center main-title">Visualizations</h1>

        {/* Models Pie Chart */}
        <div className="visualization-section">
          <h2 className="section-title">Model Distribution</h2>
          <div className="chart-container">
            <ModelsPieChart />
          </div>
        </div>

        {/* Psychiatrists Bar Graph */}
        <div className="visualization-section">
          <h2 className="section-title">Psychiatrists Insights</h2>
          <div className="chart-container">
            <PsychiatristsBarGraph />
          </div>
        </div>

        {/* Pharmacies Heat Map */}
        <div className="visualization-section">
          <h2 className="section-title">Support Group Insights</h2>
          <div className="chart-container">
            <PharmaciesHeatMap />
          </div>
        </div>

        
        <SelfCritiques />
      </Container>
    </div>
  );
};

export default Visualizations;
