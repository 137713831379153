import { useState, useEffect } from 'react';
import axios from 'axios';

export const useTeamStats = (teamMembers) => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.post('https://txadhdhub.me/api/stats', { teamMembers });
        setStats(response.data);
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, [teamMembers]);

  return stats;
};
