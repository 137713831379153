import React, { useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import CategoryResults from './CategoryResults';
import { useSearchResults } from '../hooks/useSearchResults';
import './Home.css'; // Import CSS

const useQuery = () => new URLSearchParams(useLocation().search);

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const query = useQuery();

  React.useEffect(() => {
    setSearchTerm(query.get('search_term') || '');
  }, [query]);

  const { results, loading, error } = useSearchResults(searchTerm);

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const params = new URLSearchParams();
    if (term) params.set('search_term', term);
    navigate(`?${params.toString()}`);
  };

  return (
    <div className="home-page">
      <NavigationBar activePath="/home" />

      {/* Hero Section with Background Image */}
      <div className="hero-section">
        <Container className="text-center hero-content">
          <h1 className="hero-title">Welcome to TX ADHD Hub</h1>
          <h4 className="hero-subtitle">
            Your resource for ADHD support and community engagement in Texas.
          </h4>
          <Button as="a" href="/about" variant="primary" className="hero-button">
            About Us
          </Button>
        </Container>
      </div>

      {/* Search Section */}
      <Container className="text-center search-section">
        <h2>Search Across All Resources</h2>
        <Form className="search-form" onSubmit={(e) => e.preventDefault()}>
          <Form.Group controlId="searchBar">
            <Form.Control
              type="text"
              placeholder="Search support groups, psychiatrists, or pharmacies..."
              value={searchTerm}
              onChange={handleSearchTermChange}
              className="search-bar"
            />
          </Form.Group>
        </Form>

        {loading && <p>Loading...</p>}
        {error && <p className="text-danger">{error}</p>}
        {!loading && !error && Object.keys(results).length > 0 && (
          <div>
            {Object.entries(results).map(([category, items]) => (
              <CategoryResults
                key={category}
                category={category}
                items={items}
                searchTerm={searchTerm}
              />
            ))}
          </div>
        )}
      </Container>

      <div className="footer-spacer"></div>
    </div>
  );
};

export default Home;
