import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ProviderHealthcareBubble = () => {
  const ref = useRef();

  useEffect(() => {
    // Fetch healthcare data
    fetch('https://immigraid-backend.immigraid.me/api/healthcare-data')
      .then((response) => response.json())
      .then((healthcareData) => {
        // Process state counts
        const stateCount = healthcareData.reduce((acc, facility) => {
          if (facility.state) {
            acc[facility.state] = (acc[facility.state] || 0) + 1;
          }
          return acc;
        }, {});

        // Convert to array format needed for bubble chart
        const data = Object.entries(stateCount)
          .map(([state, count]) => ({
            state,
            count
          }))
          .filter(d => d.count >= 1); // Only include states with at least 1 facility

        drawChart(data);
      })
      .catch((error) => {
        console.error('Error fetching healthcare data:', error);
      });
  }, []);

  const drawChart = (data) => {
    // Clear any existing chart
    d3.select(ref.current).selectAll("*").remove();

    const width = 928;
    const height = width;
    const margin = 1;
    const format = d3.format(",d");

    // Create pack layout
    const pack = d3.pack()
      .size([width - margin * 2, height - margin * 2])
      .padding(3);

    // Create hierarchy
    const root = pack(d3.hierarchy({ children: data })
      .sum(d => d.count));

    // Create SVG container
    const svg = d3.select(ref.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-margin, -margin, width, height])
      .attr("class", "w-full h-auto text-xs")
      .attr("text-anchor", "middle");

    // Create nodes
    const node = svg.append("g")
      .selectAll()
      .data(root.leaves())
      .join("g")
      .attr("transform", d => `translate(${d.x},${d.y})`);

    // Add circles with a healthcare-themed color
    node.append("circle")
      .attr("fill", "#ff6b6b") // Changed to a medical/healthcare red color
      .attr("fill-opacity", 0.7)
      .attr("r", d => d.r);

    // Add text
    const text = node.append("text")
      .attr("clip-path", d => `circle(${d.r})`);

    // Add state name
    text.append("tspan")
      .attr("x", 0)
      .attr("y", d => -10)
      .attr("fill", "white")
      .text(d => d.data.state);

    // Add count
    text.append("tspan")
      .attr("x", 0)
      .attr("y", d => 10)
      .attr("fill", "white")
      .text(d => format(d.data.count));
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Healthcare Facilities by State</h2>
      <div className="border rounded-lg p-4 bg-white shadow-lg">
        <div ref={ref}></div>
      </div>
    </div>
  );
};

export default ProviderHealthcareBubble;
