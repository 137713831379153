import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ProviderEducationByState = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://immigraid-backend.immigraid.me/api/educational-data');
        const educationalData = await response.json();
        
        // Process state counts
        const stateCount = educationalData.reduce((acc, program) => {
          if (program.location) {
            const parts = program.location.split(',');
            if (parts.length >= 2) {
              const state = parts[1].trim();
              acc[state] = (acc[state] || 0) + 1;
            }
          }
          return acc;
        }, {});
        
        // Convert to array and sort by count
        const formattedData = Object.entries(stateCount)
          .map(([state, count]) => ({
            state,
            count
          }))
          .sort((a, b) => b.count - a.count);
        
        setData(formattedData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div className="flex justify-center items-center p-4">Loading...</div>;
  if (error) return <div className="flex justify-center items-center text-red-500 p-4">{error}</div>;

  return (
    <div className="flex flex-col items-center w-full max-w-6xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-8">Education Programs by State</h2>
      <div className="w-full overflow-x-auto">
        <div className="min-w-[800px] flex justify-center">
          <BarChart
            width={800}
            height={500}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 40,
              bottom: 80
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="state"
              angle={-45}
              textAnchor="end"
              height={80}
              interval={0}
              tick={{ dy: 10 }}
            />
            <YAxis
              label={{ 
                value: "Number of Programs",
                angle: -90,
                position: 'insideLeft',
                dy: 60
              }}
            />
            <Tooltip />
            <Legend wrapperStyle={{ bottom: -10 }} />
            <Bar
              dataKey="count"
              fill="#4292c6"
              name="Number of Programs"
            />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default ProviderEducationByState;
