import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import About from './components/About';
import Home from './components/Home';
import GeneralInstance from './components/GeneralInstance'
import ModelList from './components/ModelList';
import Visualizations from './components/Visualizations';
import ProviderVisualizations from './components/ProviderVisualizations';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path = "/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path = "/:model" element={<ModelList />} />
        <Route path="/:model/:id" element={<GeneralInstance />} />
        <Route path="/visualizations" element={<Visualizations />} />
        <Route path="/provider-visualizations" element={<ProviderVisualizations />} />
      </Routes>
    </Router >);
};

export default App;
