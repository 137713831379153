import React from 'react';
import { Card } from 'react-bootstrap';
import './Critiques.css';

const SelfCritiques = () => {
  return (
    <section className="critique-section">
      <h2 className="section-title">Self-Critique</h2>
      <Card className="critique-card">
        <Card.Body>
          <h4>What did we do well?</h4>
          <p>
            We effectively tracked our progress using GitLab and Slack, ensuring we met all our
            milestones. Responsibilities and timelines were promptly assigned, keeping the team
            organized and efficient.
          </p>
          <h4>What did we learn?</h4>
          <p>
            We gained experience with multiple tools from scratch, including Node.js, Flask,
            Recharts, and AWS. Additionally, we learned to create databases adhering to first normal
            form and gained a deeper understanding of multiplicity relationships.
          </p>
          <h4>What did we teach each other?</h4>
          <p>
            We taught each other the importance of effective communication and teamwork, which
            significantly improved our collaboration.
          </p>
          <h4>What can we do better?</h4>
          <p>
            We recognize that we could have been more diligent about meeting deadlines and managing
            our time effectively. Additionally, fostering greater in-person collaboration would have
            likely strengthened our teamwork and streamlined our processes.
          </p>
          <h4>What effect did the peer reviews have?</h4>
          <p>
            The peer reviews provided valuable insights into areas where we could improve. They
            guided us in making meaningful changes that enhanced our teamwork and project outcomes.
          </p>
          <h4>What puzzles us?</h4>
          <p>
            Our database response time is slower than expected. While it works for our current
            purposes, we are curious about how we can optimize it for better performance.
          </p>
        </Card.Body>
      </Card>
    </section>
  );
};

export default SelfCritiques;
