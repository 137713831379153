import React from 'react';
import { Card } from 'react-bootstrap';
import './Critiques.css';

const ProviderCritiques = () => {
  return (
    <section className="critique-section">
      <h2 className="section-title">Provider Critique</h2>
      <Card className="critique-card">
        <Card.Body>
          <h4>What did they do well?</h4>
          <p>
            The visualizations on the website are clear, engaging, and well-designed, making the
            interface easy to navigate. Additionally, their loading and response times are
            impressively quick, ensuring a smooth user experience.
          </p>
          <h4>How effective was their RESTful API?</h4>
          <p>
            The RESTful API is effective and demonstrates excellent response times, which enhanced
            our integration process. However, the API documentation is incomplete and occasionally
            confusing, which made the implementation process more challenging. With more
            comprehensive and clearer documentation, the API would be significantly more
            user-friendly.
          </p>
          <h4>How well did they implement your user stories?</h4>
          <p>
            Some user stories, especially those related to bug fixes, were successfully addressed.
            However, others, like multilingual support, appear to have been only partially
            implemented, as this functionality is limited to the front page and does not extend
            site-wide.
          </p>
          <h4>What did we learn from their website?</h4>
          <p>
            We learned that immigrants often face challenges accessing reliable resources due to
            language and cultural barriers. Dedicated support systems, such as language courses and
            culturally sensitive healthcare and legal services, are vital for their integration and
            well-being. Platforms like ImmigriAid show how accessible resources can empower
            immigrants and help them thrive in new communities.
          </p>
          <h4>What can they do better?</h4>
          <p>
            The search and filtering features could be improved to enhance user experience; for
            example, implementing dropdown menus for filtering by city instead of requiring users to
            type manually. Additionally, some instance cards lack images, making the interface
            harder to navigate and less visually cohesive.
          </p>
          <h4>What puzzles us about their website?</h4>
          <p>
            The decision to display instances in groups of five despite the grid being structured as
            3x3 is puzzling and disrupts visual balance. Similarly, alignment issues on the About
            page, particularly with team member cards, detract from the website’s professional
            appearance and usability.
          </p>
        </Card.Body>
      </Card>
    </section>
  );
};

export default ProviderCritiques;
