import React from 'react';
import NavigationBar from './NavigationBar';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import ContactInfo from './ContactInfo'; // Import the ContactInfo component
import './PsychiatristTemplate.css'; // Custom CSS

const PsychiatristTemplate = ({ psychiatristData }) => {
  if (!psychiatristData) {
    return (
      <>
        <NavigationBar activePath="/psychiatrists" />
        <Container className="mt-4 text-center">
          <h2 className="error-text">Error: Licensed psychiatrist data not available.</h2>
        </Container>
      </>
    );
  }

  const {
    name,
    address: { street, city, state, zip, place_id },
    specializations,
    cost,
    phone,
    accepts_insurance: acceptsInsurance,
    description,
    photo_url,
    related_pharmacies: relatedPharmacies,
    related_support_groups: relatedSupportGroups,
    online,
    in_person: inPerson,
    waitlist,
    unavailable,
  } = psychiatristData;

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${place_id}`;

  return (
    <>
      <NavigationBar activePath="/psychiatrists" />
      <Container className="mt-4 psychiatrist-template-container">
        <Row>
          {/* Left: Psychiatrist Image, Name, and Description */}
          <Col md={6}>
            <Card className="psychiatrist-card">
              <Card.Img variant="top" src={photo_url} alt={name} className="psychiatrist-image" />
              <Card.Body>
                <Card.Title className="psychiatrist-title">{name}</Card.Title>
                <div className="description-section">
                  <h4>Description</h4>
                  <Card.Text>{description}</Card.Text>
                </div>
                {/* Embed Google Map */}
                <div className="location-section">
                  <h4>Location:</h4>
                  <p>
                    {street}, {city}, {state}, {zip}
                  </p>
                  <div className="google-maps-container">
                    <iframe
                      src={googleMapsEmbedUrl}
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex="0"
                      title="Google Maps Embed"
                      className="google-maps-iframe"
                    ></iframe>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right: Psychiatrist Details */}
          <Col md={6}>
            <Card className="psychiatrist-details-card">
              <Card.Body>
                <ContactInfo
                  phone={phone}
                  specializations={specializations}
                  cost={cost}
                  acceptsInsurance={acceptsInsurance}
                />

                <h4>Availability:</h4>
                <Card.Text className="availability-section">
                  {online && <span>Online Consultations Available</span>}
                  {inPerson && <span>In-Person Consultations Available</span>}
                  <br />
                  {waitlist ? <span>Waitlist Active</span> : <span>No Waitlist</span>}
                  {unavailable && <span>Currently Unavailable</span>}
                </Card.Text>

                <div className="button-group">
                  <Button href="#" target="_blank" rel="noopener noreferrer" variant="primary">
                    Visit Psychiatrist Website
                  </Button>
                </div>

                {/* Related connections */}
                <div className="mt-4 related-connections">
                  <h4>Related Connections:</h4>
                  {relatedPharmacies && relatedPharmacies.length > 0 && (
                    <Button
                      href={`/pharmacies/${relatedPharmacies[0]}`}
                      variant="secondary"
                      className="me-2"
                    >
                      Related Pharmacy
                    </Button>
                  )}
                  {relatedSupportGroups && relatedSupportGroups.length > 0 && (
                    <Button href={`/support-groups/${relatedSupportGroups[0]}`} variant="secondary">
                      Related Support Group
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PsychiatristTemplate.propTypes = {
  psychiatristData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      place_id: PropTypes.string.isRequired,
    }).isRequired,
    specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
    cost: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    accepts_insurance: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    photo_url: PropTypes.string.isRequired,
    related_pharmacies: PropTypes.array,
    related_support_groups: PropTypes.array,
    online: PropTypes.bool,
    in_person: PropTypes.bool,
    waitlist: PropTypes.bool,
    unavailable: PropTypes.bool,
  }).isRequired,
};

export default PsychiatristTemplate;
