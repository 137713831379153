import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import TeamMemberCard from './TeamMemberCard';
import { useTeamStats } from '../hooks/useTeamStats';

import lanceImage from '../images/lance.jpg';
import bhuvanImage from '../images/bhuvan.png';
import aneeshImage from '../images/aneesh.png';
import atillaImage from '../images/atilla.jpg';
import nikhilImage from '../images/nikhil.png';
import './About.css'; // Custom CSS file

const teamMembers = [
  {
    name: 'Lance Zhang',
    username: 'lancezhang04',
    role: 'Backend',
    image: lanceImage,
    about:
      "Hi! I'm a junior in CS and mostly contribute to the backend of this website. " +
      'Outside of academics, my interests include backpacking and philosophy. Thanks for ' +
      'checking out our project!',
  },
  {
    name: 'Bhuvan Kannaeganti',
    username: 'bhuvankann',
    role: 'Full Stack',
    image: bhuvanImage,
    about:
      "Hey, I'm Bhuvan, a junior in CS and I work in a full-stack role for this website. " +
      'In my free time, I enjoy playing pickleball, watching movies, and listening to music.',
  },
  {
    name: 'Aneesh Singh',
    username: 'FCAniche',
    role: 'Frontend',
    image: aneeshImage,
    about:
      "I'm a fourth-year CS major mostly working on the frontend for this website. " +
      'I enjoy listening to music and playing instruments in my free time, ' +
      'as well as taking walks at night.',
  },
  {
    name: 'Atilla Istami',
    username: 'Kumatamo',
    role: 'Backend/Database',
    image: atillaImage,
    about:
      "I'm a fourth-year CS major mainly working on the database side of the backend. " +
      'I love playing rhythm games and badminton.',
  },
  {
    name: 'Nikhil Kalidasu',
    username: 'srikarnikhil.kalidasu',
    role: 'Full Stack',
    image: nikhilImage,
    about:
      "I'm a third-year CS major working on data cleaning and unit testing. My other " +
      'interests include data science and machine learning.',
  },
];

function About() {
  const stats = useTeamStats(teamMembers);

  return (
    <>
      <NavigationBar activePath="/about" />
      <section className="hero-section">
        <Container>
          <h1 className="hero-title">About TX ADHD Hub</h1>
          <p className="hero-subtitle">
            Providing resources, information, and support for individuals with ADHD in underserved communities in Texas.
          </p>
        </Container>
      </section>
      <Container className="about-container">
        <section className="section">
          <h2>Our Mission</h2>
          <p>
            TX ADHD Hub connects users with healthcare professionals, support groups, and pharmacies, fostering community engagement and 
            providing resources for ADHD care.
          </p>
        </section>
        <section className="section">
          <h2>Data Sources & Tools</h2>
          <p>
            Our platform integrates data from CHADD's Professional Directory, DSHS Pharmacy List, and Google APIs, delivering 
            comprehensive resources for ADHD support.
          </p>
        </section>
        <section className="section team-section">
          <h2>Team Members</h2>
          <Row>
            {teamMembers.map((member) => (
              <Col key={member.username} md={4} className="mb-4 d-flex align-items-stretch">
                <TeamMemberCard member={member} stats={stats[member.username]} />
              </Col>
            ))}
          </Row>
        </section>
        <section className="section">
          <h2>API Documentation</h2>
          <p>
            Our website's code is thoroughly documented on Postman. Check it out{' '}
            <a
              href="https://documenter.getpostman.com/view/15226336/2sAXxLAth6"
              target="_blank"
              rel="noreferrer"
              className="api-link"
            >
              here
            </a>.
          </p>
        </section>
      </Container>
    </>
  );
}

export default About;