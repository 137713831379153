import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';


const PharmaciesHeatMap = () => {

    const ref = useRef();

    useEffect(() => {
        // Fetch specializations data
        fetch('/api/specializations')
          .then((response) => response.json())
          .then((data) => {
            drawChart(data);
          })
          .catch((error) => {
            console.error('Error fetching specializations:', error);
          });
      }, []);

    const drawChart = (specializations) => {
        // Process data: Get the number of support group per specialization
        const data = specializations
            .map((spec) => ({
                spec: spec.name,
                count: spec.support_groups.length,
          }))
            .filter((d) => d.count >= 1); // Only include specializations with at least 1 support group
            console.log(data);
        const width = 928;
        const height = width;
        const margin = 1; // avoid clipping the root circle stroke
        const names = d => (d).split(' ');
        const format = d3.format(",d") // likely unnecessary since I don't think there'd be more than 1,000
        const color = d3.scaleOrdinal(d3.schemeTableau10); // color scale
        const pack = d3.pack()
            .size([width - margin * 2, height - margin * 2])
            .padding(3);
        const root = pack(d3.hierarchy({children: data})
            .sum(d => d.count));

        // SVG container
        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-margin, -margin, width, height])
            .attr("style", "max-width: 100%; height: auto; font: 9px sans-serif;")
            .attr("text-anchor", "middle");
        
        const node = svg.append("g")
            .selectAll()
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x},${d.y})`);
        
        node.append("circle")
            .attr("fill-opacity", 0.7)
            .attr("fill", 'blue')
            .attr("r", d => d.r);
        const text = node.append("text")
            .attr("clip-path", d => `circle(${d.r})`);
        
        // add tspan of specialization name
        text.selectAll()
            .data(d => names(d.data.spec))
            .join("tspan")
              .attr("x", 0)
              .attr("y", (d, i, nodes) => `${i - nodes.length / 2 + 0.35}em`)
              .text(d => d)
              //.attr('font-size', d => console.log(d));
            
        
        // add tspan of specialization amount
        text.append("tspan")
            .attr("x", 0)
            .attr("y", d => `${names(d.data.spec).length / 2 + 0.35}em`) // make sure below specialization
            .attr("fill-opacity", 0.7)
            .text(d => format(d.data.count))
            //.attr('font-size', d => (d.data.count) / 5 + 5);
    }
    return (
        <div>
            <h2 className="text-center">Number of Support Groups with Specialization</h2>
            <div ref={ref}></div>
        </div>
    )
}



export default PharmaciesHeatMap;