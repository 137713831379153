import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ModelsPieChart = () => {
    const ref = useRef();

    useEffect(() => {
        // Fetch model counts
        fetch('/api/model-counts')
            .then((response) => response.json())
            .then((data) => {
                drawChart(data);
            })
            .catch((error) => {
                console.error('Error fetching model counts:', error);
            });
    }, []);

    const drawChart = (data) => {
        const width = 450;
        const height = 450;
        const margin = 40;

        const radius = Math.min(width, height) / 2 - margin;

        const svg = d3.select(ref.current)
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const modelData = [
            { model: 'Support Groups', count: data.support_groups },
            { model: 'Psychiatrists', count: data.psychiatrists },
            { model: 'Pharmacies', count: data.pharmacies }
        ];

        const color = d3.scaleOrdinal()
            .domain(modelData.map(d => d.model))
            .range(d3.schemeCategory10);

        const pie = d3.pie()
            .value(d => d.count);

        const data_ready = pie(modelData);

        svg
            .selectAll('whatever')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(radius)
            )
            .attr('fill', d => color(d.data.model))
            .attr('stroke', 'white')
            .style('stroke-width', '2px')
            .style('opacity', 0.7);

        // Add labels
        svg
            .selectAll('text')
            .data(data_ready)
            .enter()
            .append('text')
            .text(d => `${d.data.model}: ${d.data.count}`)
            .attr('transform', d => `translate(${d3.arc()
                .innerRadius(0)
                .outerRadius(radius)
                .centroid(d)})`)
            .style('text-anchor', 'middle')
            .style('font-size', 14);
    };

    return (
        <div>
            <h2 className="text-center">Total Counts of Each Model</h2>
            <svg ref={ref}></svg>
        </div>
    );
};

export default ModelsPieChart;
