import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './SupportGroupFilter.css';
import uniqueCities from '../filterData/support_groups_cities.json';
import uniqueZipCodes from '../filterData/support_groups_zip_codes.json';
import uniqueAgeGroups from '../filterData/support_groups_age_groups.json';
import uniqueTherapyTypes from '../filterData/support_groups_therapy_types.json';

const SupportGroupFilter = ({
  city,
  setCity,
  zipCode,
  setZipCode,
  ageGroup,
  setAgeGroup,
  selectedTherapyTypes,
  setSelectedTherapyTypes,
  selectedDays,
  setSelectedDays,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <Form className="support-group-filter">
      <Row className="align-items-center justify-content-center filter-row">
        {/* City */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupCity">
            <Form.Label>City</Form.Label>
            <Typeahead
              id="city-typeahead"
              onChange={(selected) => setCity(selected[0] || '')}
              options={uniqueCities}
              placeholder="Choose a city..."
              selected={city ? [city] : []}
              highlightOnlyResult
              allowNew={false}
              className="typeahead-input"
            />
          </Form.Group>
        </Col>

        {/* Zip Code */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupZipCode">
            <Form.Label>Zip Code</Form.Label>
            <Typeahead
              id="zip-code-typeahead"
              onChange={(selected) => setZipCode(selected[0] || '')}
              options={uniqueZipCodes}
              placeholder="Choose a zip code..."
              selected={zipCode ? [zipCode] : []}
              highlightOnlyResult
              allowNew={false}
              className="typeahead-input"
            />
          </Form.Group>
        </Col>

        {/* Age Group */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupAgeGroup">
            <Form.Label>Age Group</Form.Label>
            <Form.Control
              as="select"
              value={ageGroup || ''}
              onChange={(e) => setAgeGroup(e.target.value)}
              className="select-input"
            >
              <option value="">Any</option>
              {uniqueAgeGroups.map((ageGroupOption, index) => (
                <option key={index} value={ageGroupOption}>
                  {ageGroupOption}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Therapy Types */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupTherapyTypes">
            <Form.Label>Therapy Types</Form.Label>
            <Typeahead
              id="therapy-types-typeahead"
              onChange={(selected) => setSelectedTherapyTypes(selected)}
              options={uniqueTherapyTypes}
              placeholder="Select therapy types..."
              selected={selectedTherapyTypes}
              highlightOnlyResult
              multiple
              allowNew={false}
              className="typeahead-input"
            />
          </Form.Group>
        </Col>

        {/* Days */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupDays">
            <Form.Label>Days</Form.Label>
            <Form.Control
              as="select"
              multiple
              value={selectedDays}
              onChange={(e) =>
                setSelectedDays(Array.from(e.target.selectedOptions, (option) => option.value))
              }
              className="multiple-select"
            >
              {daysOfWeek.map((dayOption, index) => (
                <option key={index} value={dayOption.toLowerCase()}>
                  {dayOption}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Sort By */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupSortBy">
            <Form.Label>Sort By</Form.Label>
            <Form.Control
              as="select"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="select-input"
            >
              <option value="">Default</option>
              <option value="name">Name</option>
              <option value="city">City</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Sort Order */}
        <Col xs="12" sm="6" md="4" lg="3" className="filter-col">
          <Form.Group controlId="supportGroupSortOrder">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              as="select"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              className="select-input"
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default SupportGroupFilter;
